import './App.css';
import { useState, useEffect } from 'react';
// import logo from './../public/logo.png';

function App() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "November, 7, 2024";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className='bg-[#68d4e2] w-[100vw] h-[100vh] flex flex-col justify-between'>
      <div className='flex justify-between'>
        <div>
          <img className='xl:w-72 xl:pt-10 xl:pl-10 sm:w-[60vw] sm:pt-10 sm:pl-5 md:w-[50vw] md:pl-10' src='/logo.png' alt='logo' />
        </div>
        <div className='flex xl:mt-10 sm:mt-14'>
          <a href='https://www.linkedin.com/company/amplesavings/' target='_blank' rel="noopener noreferrer"><img className='xl:w-10 xl:h-10 xl:mr-4 xl:mt-[3px] sm:w-5 sm:h-5 sm:mr-2 md:w-10 md:h-10 md:mr-4' src='/Instagram.png' alt='logo' /></a>
          <a href='https://www.linkedin.com/company/amplesavings/' target='_blank' rel="noopener noreferrer"><img className='xl:w-9 xl:h-9 xl:mr-4 xl:mt-[4px] sm:w-4 sm:h-4 sm:mr-2 sm:mt-[2px] md:w-9 md:h-9 md:mr-4' src='/linkedin.png' alt='logo' /></a>
          <a href='https://www.linkedin.com/company/amplesavings/' target='_blank' rel="noopener noreferrer"><img className='xl:w-10 xl:h-10 xl:mr-10 sm:w-5 sm:h-5 sm:mr-5 md:w-10 md:h-10 md:mr-10' src='/facebook.png' alt='logo' /></a>
        </div>
      </div>
      <div className='flex sm:flex-col sm:space-between xl:flex-row'>
        <div className='mt-[5vh] md:ml-10 sm:ml-5'>
          <p className='xl:text-[10rem] md:text-[5rem] sm:text-[2rem] text-white font-bold font-Roboto'>COMING</p>
          <p className='xl:text-[10rem] md:text-[5rem] sm:text-[2rem] xl:mt-[-10vh] sm:mt-[-1vh] text-white font-bold font-Roboto'>SOON.</p>
          <p className='text-white xl:text-2xl md:text-xl sm:text-sm font-Roboto font-extralight xl:ml-4 xl:mt-[-3vh] sm:mt-[1vh]'>We are launching soon, follow us for updates...</p>
          <div className='flex xl:mt-20 sm:mt-10'>
            <div className='xl:mr-5 sm:mr-2'>
              <p className='text-center font-Roboto font-semibold text-white xl:text-3xl md:text-2xl sm:text-xl mb-2'>{days}</p>
              <p className='font-Roboto font-semibold text-white xl:text-3xl md:text-xl sm:text-lg'>DAYS</p>
            </div>
            <div className='xl:mr-5 sm:mr-2'>
              <p className='text-center font-Roboto font-semibold text-white xl:text-3xl md:text-2xl sm:text-xl mb-2'>{hours}</p>
              <p className='font-Roboto font-semibold text-white xl:text-3xl md:text-xl sm:text-lg'>HOURS</p>
            </div>
            <div className='xl:mr-5 sm:mr-2'>
              <p className='text-center font-Roboto font-semibold text-white xl:text-3xl md:text-2xl sm:text-xl mb-2'>{minutes}</p>
              <p className='font-Roboto font-semibold text-white xl:text-3xl md:text-xl sm:text-lg'>MINUTES</p>
            </div>
            <div className='xl:mr-5 sm:mr-2'>
              <p className='text-center font-Roboto font-semibold text-white xl:text-3xl md:text-2xl sm:text-xl mb-2'>{seconds}</p>
              <p className='font-Roboto font-semibold text-white xl:text-3xl md:text-xl sm:text-lg'>SECONDS</p>
            </div>
          </div>
        </div>
        <div>
          <img className='xl:mt-[26.5vh] xl:h-[60vh] xl:w-[55vw] md:mt-[6.5vh] sm:mt-[3.5vh] sm:h-[45vh] sm:w-[100vw]' src="/Micro-Entreprenuer1.png" alt='micro-entreprenuer' />
        </div>
      </div>

    </div>
  );
}

export default App;
